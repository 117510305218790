/*.handheld-router {*/
/*    height: 100vh;*/
/*    width: auto;*/
/*}*/

.handheld-router-root {
  display: flex;
  flex-direction: column;
  justify-items: auto;
  height: 100%;
  width: 100%;
}

.handheld-router-sp {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 0;
  max-width: 100%;
  height: 100%;
  /*overflow-x: hidden;*/
  /*z-index: 0;*/
}

/**/
.handheld-router-ta {
  display: flex;
  width: 0;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
}
