/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins"), url("assets/fonts/poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsMedium";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins"), url("assets/fonts/poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  src: local("Poppins"), url("assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Vapiano";
  font-style: normal;
  font-weight: normal;
  src: local("Vapiano"), url("assets/fonts/vapiano/VapianoPro-Regular.woff") format("woff");
}

@font-face {
  font-family: "Vapiano";
  font-style: normal;
  font-weight: bold;
  src: local("Vapiano"), url("assets/fonts/vapiano/VapianoDeco-Bold.woff") format("woff");
}

@font-face {
  font-family: "VapianoBold";
  font-style: normal;
  font-weight: normal;
  src: local("VapianoBold"), url("assets/fonts/vapiano/VapianoDeco-Bold.woff") format("woff");
}

@font-face {
  font-family: "VapianoLight";
  font-style: normal;
  font-weight: normal;
  src: local("VapianoLight"), url("assets/fonts/vapiano/VapianoSans-Light.woff") format("woff");
}

@font-face {
  font-family: "VapianoRegular";
  font-style: normal;
  font-weight: normal;
  src: local("VapianoRegular"), url("assets/fonts/vapiano/VapianoSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "VapianoSemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("VapianoSemiBold"), url("assets/fonts/vapiano/VapianoSansProCond-Semibold.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 35 Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 35 Thin"), url("assets/fonts/HelveticaNeue-Thin.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 37 Thin Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 37 Thin Condensed"), url("assets/fonts/HelveticaNeue-ThinCond.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 37 Thin Condensed Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 37 Thin Condensed Oblique"), url("assets/fonts/HelveticaNeue-ThinCondObl.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 33 Thin Extended Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 33 Thin Extended Oblique"), url("assets/fonts/HelveticaNeue-ThinExtObl.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 36 Thin Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 36 Thin Italic"), url("assets/fonts/HelveticaNeue-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 43 Light Extended";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 43 Light Extended"), url("assets/fonts/HelveticaNeue-LightExt.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 27 Ultra Light Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 27 Ultra Light Condensed"), url("assets/fonts/HelveticaNeue-UltraLigCond.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 23 Ultra Light Extended";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 23 Ultra Light Extended"), url("assets/fonts/HelveticaNeue-UltraLigExt.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 47 Light Condensed Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 47 Light Condensed Oblique"), url("assets/fonts/HelveticaNeue-LightCondObl.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 43 Light Extended Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 43 Light Extended Oblique"), url("assets/fonts/HelveticaNeue-LightExtObl.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 27 Ultra Light Condensed Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 27 Ultra Light Condensed Oblique"),
    url("assets/fonts/HelveticaNeue-UltraLigCondObl.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 83 Heavy Extended";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 83 Heavy Extended"), url("assets/fonts/HelveticaNeue-HeavyExt.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 53 Extended";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 53 Extended"), url("assets/fonts/HelveticaNeue-Extended.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 85 Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 85 Heavy"), url("assets/fonts/HelveticaNeue-Heavy.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 87 Heavy Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 87 Heavy Condensed"), url("assets/fonts/HelveticaNeue-HeavyCond.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 107 Extra Black Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 107 Extra Black Condensed"), url("assets/fonts/HelveticaNeue-ExtBlackCond.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 57 Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 57 Condensed"), url("assets/fonts/HelveticaNeue-Condensed.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 63 Medium Extended";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 63 Medium Extended"), url("assets/fonts/HelveticaNeue-MediumExt.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 55 Roman";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 55 Roman"), url("assets/fonts/HelveticaNeue-Roman.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 83 Heavy Extended Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 83 Heavy Extended Oblique"), url("assets/fonts/HelveticaNeue-HeavyExtObl.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 87 Heavy Condensed Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 87 Heavy Condensed Oblique"), url("assets/fonts/HelveticaNeue-HeavyCondObl.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 53 Extended Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 53 Extended Oblique"), url("assets/fonts/HelveticaNeue-ExtendedObl.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 107 Extra Black Condensed Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 107 Extra Black Condensed Oblique"),
    url("assets/fonts/HelveticaNeue-ExtBlackCondObl.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 57 Condensed Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 57 Condensed Oblique"), url("assets/fonts/HelveticaNeue-CondensedObl.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 86 Heavy Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 86 Heavy Italic"), url("assets/fonts/HelveticaNeue-HeavyItalic.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 63 Medium Extended Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 63 Medium Extended Oblique"), url("assets/fonts/HelveticaNeue-MediumExtObl.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 73 Bold Extended";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 73 Bold Extended"), url("assets/fonts/HelveticaNeue-BoldExt.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 73 Bold Extended Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 73 Bold Extended Oblique"), url("assets/fonts/HelveticaNeue-BoldExtObl.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 77 Bold Condensed Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 77 Bold Condensed Oblique"), url("assets/fonts/HelveticaNeue-BoldCondObl.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 93 Black Extended";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 93 Black Extended"), url("assets/fonts/HelveticaNeue-BlackExt.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 95 Black";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 95 Black"), url("assets/fonts/HelveticaNeue-Black.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 93 Black Extended Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 93 Black Extended Oblique"), url("assets/fonts/HelveticaNeue-BlackExtObl.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Neue Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica Neue Medium"), url("assets/fonts/HelveticaNeueMedium.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 67 Medium Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 67 Medium Condensed"), url("assets/fonts/HelveticaNeue-MediumCond.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 67 Medium Condensed Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 67 Medium Condensed Oblique"),
    url("assets/fonts/HelveticaNeue-MediumCondObl.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 97 Black Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 97 Black Condensed"), url("assets/fonts/HelveticaNeue-BlackCond.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 97 Black Condensed Oblique";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 97 Black Condensed Oblique"), url("assets/fonts/HelveticaNeue-BlackCondObl.woff") format("woff");
}

@font-face {
  font-family: "Helvetica 96 Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica 96 Black Italic"), url("assets/fonts/HelveticaBlkIt.woff") format("woff");
}
