.empty-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 10px 7.5%;
  border-bottom: gray 1px solid;
}
