.floor-plan-page {
  display: flex;
  flex-direction: column;
}

.floor-plan-page .floor-plan-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.floor-plan-page .floor-plan-buttons button {
  margin: 10px;
}
