.header-page {
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  border-top: darkgrey 1px solid;
  border-bottom: darkgrey 1px solid;
}

.header-page .header-page-title {
  min-height: 45px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.header-page .header-page-title p {
  text-align: center;
  font-size: 20px;
  margin: 0;
}

.header-page .header-sticky-part {
  z-index: 10;
  top: 54px;
  left: 0;
  right: 0;
  border-top: darkgrey 1px solid;
}
