.button-myn {
  color: black;
  background-color: white;
  text-align: center;
  border-radius: 1em;
  font-size: 1em;
  font-family: inherit;
  border: 1px solid black;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button-myn:hover {
  color: black;
  background-color: antiquewhite;
}

.button-myn img {
  display: block;
  margin: 12px auto;
  width: 36px;
  height: 36px;
}
