.row-receipt-article {
  width: inherit;
  flex-direction: row;
  display: flex;
  padding: 10px 0;
}

.row-receipt-article p {
  margin: 0;
  padding: 0 0 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.column-receipt-amount {
  flex: 0 0 15%;
  align-self: flex-start;
}

.column-receipt-name {
  flex: 0 0 65%;
  align-self: flex-start;
  overflow: hidden;
}

.column-receipt-price {
  flex: 0 0 20%;
  align-self: flex-start;
  text-align: right;
}
