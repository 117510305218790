.drawer-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 150;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  width: 100vw;
  max-width: 320px;
  border-right: lightgrey 1px solid;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

/* Modal Content */
.drawer-menu .drawer-menu-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
}

.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
