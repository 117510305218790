.qr-scanner-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.qr-scanner-container .qr-scanner-view {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
}

.qr-scanner-container .qr-scanner-back-button {
  position: absolute;
  z-index: 50;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  outline: none;
  transition: all 0.5s ease;
  border: 1px solid black;
}

.qr-scanner-container .qr-scanner-back-button:hover {
  background: rgba(255, 255, 255, 1);
  fill: darksalmon;
  border-color: darksalmon;
  transform: scale(2) translateX(25%) translateY(-25%);
}
