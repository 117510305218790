.table-overview-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
  width: 100%;
  align-self: center;
}

.table-overview-page a:link {
  text-decoration: none;
  color: black;
}

.table-overview-page a:visited {
  text-decoration: none;
  color: black;
}

.table-overview-page a:hover {
  text-decoration: none;
  color: black;
}

.table-overview-page a:active {
  text-decoration: none;
  color: black;
}
