.qr-code-form {
  color: white;
  font-size: 1em;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  margin: 1em 1em 0 1em;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
}

.qr-code-form form {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5em 0;
  font-family: inherit;
}

.qr-code-form input {
  max-width: 500px;
  width: 51%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.2em;
  font-family: inherit;
  text-align: center;
  margin-bottom: 10px;
  padding: 10px;
}

.qr-code-form input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.qr-code-form input[type="number"] {
  -moz-appearance: textfield;
}

.qr-code-form span {
  font-size: 100%;
}
