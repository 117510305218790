.order-page {
  height: 0;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.order-page .order-page-bottom button {
  width: 66%;
}

.order-page .order-page-bottom {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;
  width: 100%;
}

.order-page .order-page-content {
  overflow-y: auto;
  flex: 1 1 auto;
  height: 0;
}
