.search-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px;
}

.search-bar-container .search-bar-input:focus {
  outline: none;
}
