.handheld-receipt-container {
  padding: 5px;
  overflow-y: auto;
  flex: 1 1 auto;
  flex-basis: 0;
  max-width: 800px;
  width: 100%;
  align-self: center;
}

.handheld-receipt-container .bold {
  font-weight: 800;
}
