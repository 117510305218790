.header-merchant {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 20vh;
  /*overflow-x: hidden;*/
}

.header-merchant img {
  display: block;
  margin: 0;
  max-height: 20vh;
  width: 100%;
  object-fit: contain;
}
