.activate-container-root {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 0;
}

.activate-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-self: center;
  width: 100%;
  max-width: 800px;
}
