.receipt-page {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 0;
}

.receipt-page .receipt-page-content {
  overflow-y: auto;
  flex: 1 1 auto;
  height: 0;
}

.receipt-page .receipt-page-bottom {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
