.qr-code-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 100vh;
  /*background-image: url("../../assets/images/homepage-qrcode.jpeg");*/
  background-size: cover;
  background-position: center;
  color: white;
  font-size: 1em;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.qr-code-page .qr-code-form input {
  text-transform: uppercase;
}

.qr-code-page .home-page-bottom {
  display: flex;
  flex-direction: column;
  margin: 0 1em 0 1em;
}

.qr-code-page .button-myn {
  width: 51%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.qr-code-page .home-page-input-qr {
  display: none;
}

.qr-code-page .qr-scanner-view-home {
  display: none;
  width: 0;
  height: 0;
}

.home-page-text {
  margin: 15px 15px;
  text-align: center;
}

.qr-code-page .bundle-version-visible {
  font-size: small;
  color: #ffffff;
  opacity: 0.25;
}

.qr-code-page .bundle-version-hidden {
  font-size: small;
  color: #ffffff;
  opacity: 0;
}
