.return-page {
  /*background-image: url("../../../assets/images/homepage-qrcode.jpeg");*/
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 100vh;
  color: white;
  font-size: 1em;
  align-items: center;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.return-page .payment-status-view {
  margin: 15px 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.return-page .payment-status-view button {
  max-width: 250px;
  margin: 10px 0;
}
