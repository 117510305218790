.top-navigation {
  height: 54px;
  display: flex;
}

.top-navigation > div {
  /*flex: 1;*/
}

.top-navigation .top-navigation-options {
  display: flex;
  height: 100%;
  align-items: center;
  flex-shrink: 1;
  border-radius: 0;
  font-size: 1em;
  font-family: inherit;
}

@media only screen and (max-width: 720px) {
  .top-navigation .top-navigation-options p {
    display: none;
  }
}

.top-navigation .top-navigation-options {
}

.top-navigation .top-navigation-options .top-navigation-options-icon {
  height: 24px;
  width: 24px;
}

.top-navigation .top-navigation-merchant-box-name {
  display: flex;
  width: 100%;
  height: 54px;
  margin: 0 54px;
  padding: 0;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 1em;
  font-family: inherit;
}

.top-navigation p {
  margin: 0;
}

.top-navigation .top-navigation-merchant-box-name p {
  text-align: center;
  font-weight: 800;
  text-transform: uppercase;
}
