.view-order-article {
  flex-direction: row;
  display: flex;
}

.view-order-article p {
  margin: 0;
  padding: 0 0 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.view-order-article .column-order-amount {
  flex: 0 0 30%;
  align-self: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.view-order-article .column-order-name {
  padding: 10px 0;
  flex: 1 1 auto;
  width: 0;
  align-self: flex-start;
}

.view-order-article .column-order-price {
  margin-right: 4px;
  padding: 10px 0;
  flex: 0 0 20%;
  align-self: flex-start;
  text-align: right;
}

.view-order-article .amount-add-remove {
  font-size: 1em;
  /*flex: 1;*/
}
