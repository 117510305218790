.table-order-container-root {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 0;
}

.table-order-container-root .table-order-container {
  flex: 1 1 auto;
  height: 0;
  display: flex;
  flex-direction: column;
}

.merchant-container-header {
  display: flex;
  flex-direction: column;
}

.merchant-container-header-wide {
  z-index: 130;
  flex: 0 0 66.7%;
}

.merchant-container-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.sticky-header-iframe {
  top: 0;
}
