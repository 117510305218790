.container-articlegroup-handheld {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.title-articlegroup-handheld {
  font-size: 20px;
  margin: 0 25px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 3px;
}

.view-articlegroup-handheld {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 8px;
}

.view-articlegroup-column-handheld {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 3px;
}
