.list-receipt-articles {
  margin: 0;
  padding: 0 8px;
  border-bottom: gray 1px solid;
}

.text-receipt-total {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: lightgray 1px solid;
}
