.total-receipt-overview {
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
}

.total-receipt-overview .receipt-article-views {
  width: 100%;
}

.total-receipt-overview .align-right {
  text-align: end;
  font-weight: 800;
}

.total-receipt-overview .bold {
  font-weight: 800;
}

.total-receipt-overview .button-myn {
  margin-top: 10px;
}
