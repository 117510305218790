.login-handheld-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 100vh;
  /*background-image: url("../../../assets/images/homepage-qrcode.jpeg");*/
  background-size: cover;
  background-position: center;
  color: white;
  font-size: 1em;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
