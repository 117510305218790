.scrollable-tab-bar-root {
  display: flex;
  flex-direction: row;
  align-content: center;
  flex: 1;
  flex-wrap: nowrap;
  overflow: auto;
  /*-ms-overflow-style: -ms-autohiding-scrollbar; !* [4] *!*/
}

.scrollable-tab-bar-root-borders {
  border-bottom: 2px solid rgb(239, 239, 244);
}

.scrollable-tab-bar {
  display: flex;
  flex: 1 1 auto;
  width: 0;
}

.scrollable-tab-bar-root::-webkit-scrollbar {
  /*display: none;*/
}
