.article-view-container {
  justify-self: center;
  cursor: pointer;
}

@media (max-width: 560px) {
  .article-view-container {
    width: 100%;
  }
}

.article-view-container-selected {
  width: 100%;
  border: 1px solid lightgrey;
  background-color: white;
}

.article-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.truncate-overflow {
  --lh: 1.5rem;
  line-height: 0.6rem;
  --max-lines: 3;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem; /* space for ellipsis */
}

.text-disabled {
  color: lightgray;
}

.article-view .image {
  flex: 0 0 30%;
  margin: 0 3px;
  height: 90%;
  text-align: center;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: center;*/
  overflow: hidden;
}

.article-view img {
  /*height: 100%;*/
  /*width: 100%;*/
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.article-view .article-right-container {
  display: flex;
  flex-direction: column;
  height: 95px;
  flex: 1;
  padding: 3px;
  overflow: hidden;
  justify-content: space-between;
}

.article-view .article-name {
  padding-bottom: 3px;
}

.article-view .article-bottom-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: black;
}

.article-view .article-price {
  white-space: nowrap;
}

.article-view-handheld {
  height: auto;
}

.article-view-handheld .article-name {
  flex: 1;
}

.article-view-handheld .article-bottom-info {
  margin-left: 3px;
}

.article-view-handheld .column-amount-content {
  margin-left: 6px;
}
