.login-form {
  color: white;
  font-size: 1em;
  /*text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;*/
  margin: 1em;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  justify-items: center;
}

.login-form .button-myn {
  display: block;
  width: 51%;
  max-width: 500px;
  border: 2px solid transparent;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.login-form input {
  display: block;
  max-width: 500px;
  width: 51%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 10px;
  padding: 10px;
}
