.button-drawer-menu {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  align-items: center;
  display: flex;
  flex-direction: row;
  border-top: lightgrey 1px solid;
  margin: 0;
  font-size: 1em;
  font-family: inherit;
  border-radius: 0;
}

.icon-drawer-menu {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
