.bottom-gradient {
  background-image: linear-gradient(to top, #000, #fff);
  position: fixed;
  left: 0;
  height: 36px;
  opacity: 0.3;
  pointer-events: none;
  transition: opacity 0.5s ease;
}

.bottom-gradient-small {
  width: 100%;
}

.bottom-gradient-wide {
  width: 70%;
}

.bottom-gradient-high {
  bottom: 54px;
}

.bottom-gradient-low {
  bottom: 0;
}

.invisible {
  opacity: 0;
}
