.ta-receipt-view {
  border-top: 1px solid;
  border-bottom: 0;
  /*border-bottom: 1px solid black;*/
  /*margin: 1px;*/
  padding: 5px;
  align-items: center;

  min-height: 80px;
}

.ta-receipt-view-active {
  background-color: orangered;
}

.ta-receipt-view-inactive {
  background-color: lightgrey;
}
