.takeaway-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  flex: 1 1 auto;
  font-size: 1em;
  overflow-y: auto;
}

/*bottomNavMargin_0*/
.takeaway-page .takeaway-page-scrollable {
  flex: 1 1 auto;
  padding: 30px;
}

.takeaway-page p {
  margin-bottom: 2px;
}

.takeaway-page form {
  width: 100%;
  flex: 1 1 auto;
  height: 0;
  display: flex;
  flex-direction: column;
}

.takeaway-page input {
  max-width: 331px;
}

.takeaway-page .takeaway-details form .filler {
  flex: 1 1 auto;
  background-color: transparent;
}

.takeaway-page .header-page {
  width: 100%;
}

.takeaway-page .horizontal-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 340px;
  margin: 0 auto;
  padding: 0 8px;
  width: calc(75% - 20px);
}
