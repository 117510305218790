.handheld-container-root {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.handheld-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
