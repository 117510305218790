.list-order-articles {
  margin: 0 8px;
  padding: 0;
  border-bottom: gray 1px solid;
  overflow: hidden;
}

.text-order-total {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: lightgray 1px solid;
}
