/* Apply CSS to iOS affected versions only */
body.iosBugFixCaret.modal-open {
  position: fixed;
  width: 100%;
}

button {
  font-family: inherit;
  text-decoration: none;
  padding: 10px 15px;
  background: transparent;
  border: none;
  cursor: pointer;
}

button:focus {
  text-decoration: none;
  outline: none;
}

html {
  height: 100%;
  /*overflow: hidden;*/
}

#sticky-header-menu-bar {
  position: -webkit-sticky;
  position: sticky;
}

body {
  height: 100%;
  font-family: "Open Sans", "Helvetica 55 Roman", "Helvetica 57 Condensed", serif;
  margin: 0;
  background-color: whitesmoke;
  scroll-behavior: smooth;
  cursor: default;
  -webkit-overflow-scrolling: touch; /* [3] */
  -ms-overflow-style: -ms-autohiding-scrollbar; /* [4] */
  /*overscroll-behavior-y: contain;*/
}

input {
  font-family: inherit;
}

div {
  font-family: inherit;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

p {
  font-family: inherit;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

span {
  font-family: inherit;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

* {
  touch-action: manipulation;
}

.root {
  /*width: 100vw;*/
  height: 100%;
}

.app {
  width: 100vw;
  height: 100vh;
  text-align: center;
  background-color: black;
  color: whitesmoke;
}

.app-logo {
  height: 250px;
  width: 250px;
  object-fit: contain;
}

.app-header {
  background-color: whitesmoke;
  height: 250px;
  color: black;
}

.app-intro {
  font-size: 1.2em;
}

.background-overlay {
  display: none;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 140;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.background-overlay-visible {
  display: flex;
}

.position-fixed {
  position: fixed;
  z-index: 100;
}

.position-absolute {
  position: absolute;
  z-index: 100;
}

.position-sticky {
  position: sticky;
  position: -webkit-sticky;
  z-index: 100;
}

.sticky-top {
  top: 0;
}

.sticky-header {
  top: 54px;
}

.sticky-footer {
  bottom: 54px;
}

.sticky-bottom {
  bottom: 0;
}

.fade-in {
  -webkit-animation: fade-in 0.5s normal;
  animation: fade-in 0.5s normal;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
