.delivery-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  flex: 1 1 auto;
  font-size: 1em;
  overflow-y: auto;
  /*text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;;*/
}

.delivery-page .delivery-page-scrollable {
  flex: 1 1 auto;
  padding: 30px;
}

.delivery-page .header-page {
  width: 100%;
}

.delivery-page .delivery-page-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
}

.delivery-page p {
  margin-bottom: 2px;
}

.delivery-page .header-page p {
  margin: 0 5px;
}

.delivery-page form .filler {
  flex: 1 1 auto;
  height: 0;
  background-color: transparent;
}

.delivery-page form {
  width: 100%;
  flex: 1 1 auto;
  height: 0;
  display: flex;
  flex-direction: column;
}

.delivery-page .horizontal-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 340px;
  margin: 0 auto;
  padding: 0 8px;
  width: calc(75% - 20px);
}
