.container-articlegroup {
  display: flex;
  flex-direction: column;
  padding-top: 32px;
}

.view-articlegroup {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 8px;
}

.view-articlegroup-column {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 3px;
}
