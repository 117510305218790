.view-phone-number {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid lightgray;
}

.view-phone-number p {
  width: 70%;
  text-align: center;
  padding: 0;
  margin: 0;
  align-self: center;
  font-size: 0.8em;
}

.view-phone-number .button-myn {
  border: 1px black solid;
}
