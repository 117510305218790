.wide-screen-page {
  flex: 1 1 auto;
  height: 0;
  display: flex;
  flex-direction: row;
}

.split-screen-left {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 7;
  width: 70%;
}

.split-screen-right {
  box-shadow: -1px 3px 3px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: -1px 3px 3px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 3;
  width: 30%;
}
