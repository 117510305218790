.header-myn {
  display: flex;
  flex-direction: row;
  height: 15vh;
  margin-top: 10px;
  /*: center;*/
  justify-content: center;
}

.header-myn img {
  margin: 5px;
  object-fit: contain;
  max-width: 80%;
  height: 100%;
}
